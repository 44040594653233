<template>
    <div id="cartodayColumn" :style="{ width: '100%', height: '100%' }"></div>
</template>
<script>
export default {
    props: {
        id: String,
    },
    components: {},
    mounted() {
        this.draw();
    },
    data() {
        return {
            random: Math.round(Math.random() * 80 + 20),
        };
    },
    methods: {
        draw() {
            var app = {};
            var chartDom = document.getElementById("cartodayColumn");
            var myChart = this.$echarts.init(chartDom, "dark");
            window.addEventListener("resize", function () {
                myChart.resize();
            });
            const categories = (function () {
                let now = new Date();
                let res = [];
                let len = 8;
                while (len--) {
                    let date = now.toLocaleTimeString().replace(/^\D*/, "")
                    let newDate = date.substring(0, date.length - 5) + '00'
                    res.unshift(newDate);
                    now = new Date(+now - 3600000);
                }
                return res;
            })();
            const data = (function () {
                let res = [];
                let len = 8;
                while (len--) {
                    res.push(Math.round(Math.random() * 100));
                }
                return res;
            })();
            const option = {
                backgroundColor: "",
                title: {
                    text: "",
                    x: "center",
                    y: "4%",
                    textStyle: {
                        color: "#fff",
                        fontSize: "22",
                    },
                    subtextStyle: {
                        color: "#90979c",
                        fontSize: "16",
                    },
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                    // trigger: "item",
                    formatter: function (parms) {
                        console.log(678, parms);
                        var str =
                            parms[0].name +
                            '车辆数' +
                            "</br>" +
                            parms[0].marker +
                            "" +
                            parms[0].value +
                            '辆'
                        parms.name
                        return str;
                    },
                },
                grid: {
                    top: "15%",
                    right: "4%",
                    left: "8%",
                    bottom: "16%",
                },
                xAxis: [
                    {
                        type: "category",
                        data: categories,
                        axisLine: {
                            lineStyle: {
                                color: "rgba(255,255,255,0.12)",
                            },
                        },
                        axisLabel: {
                            margin: 10,
                            color: "#07DBFF",
                            textStyle: {
                                fontSize: 14,
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        name: "车辆数",
                        nameTextStyle: {
                            color: "#07DBFF",
                        },
                        axisLabel: {
                            formatter: "{value}",
                            color: "#07DBFF",
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "rgba(255,255,255,0.12)",
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: "rgba(255,255,255,0.12)",
                            },
                        },
                    },
                ],
                series: [
                    {
                        type: "bar",
                        data: data,
                        barWidth: "14px",
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "#3AB7F7", // 0% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: "#49FCED", // 100% 处的颜色
                                        },
                                    ],
                                    false
                                ),
                                barBorderRadius: [30, 30, 30, 30],
                                shadowColor: "rgba(0,160,221,1)",
                                shadowBlur: 4,
                            },
                        },
                    },
                ],
            };
            app.count = 10;
            setInterval(function () {
                let axisData = new Date().toLocaleTimeString().replace(/^\D*/, "");
                data.shift();
                data.push(Math.round(Math.random() * 100));
                categories.shift();
                categories.push(axisData);
                myChart.setOption({
                    xAxis: [
                        {
                            data: categories,
                        },
                    ],
                    series: [
                        {
                            data: data,
                        }
                    ],
                });
            }, 3600000);
            option && myChart.setOption(option);
        },
    },
};
</script>
<style lang="scss" scoped>
</style>