<template>
  <div ref="char" :style="{ width: '100%', height: '100%' }"></div>
</template>
 
<script>
import china from "./json/china.json";
import shangrao from "./json/shangrao.json";
import xzq from "./json/xzq.json";
import gxq from "./json/gxq.json";
import gfq from "./json/gfq.json";
import dxs from "./json/dxs.json";
import hfx from "./json/hfx.json";
import pyx from "./json/pyx.json";
import wnx from "./json/wnx.json";
import wyx from "./json/wyx.json";
import yanshan from "./json/yanshan.json";
import ygx from "./json/ygx.json";
import yiyang from "./json/yiyang.json";
import ysx from "./json/ysx.json";
import "echarts-gl";


export default {
  data() {
    return {
      titledata: [],
      cityProper: {
        中国: china,
        上饶市: shangrao,
        信州区: xzq,
        广信区: gxq,
        广丰区: gfq,
        德兴市: dxs,
        横峰县: hfx,
        鄱阳县: pyx,
        万年县: wnx,
        婺源县: wyx,
        铅山县: yanshan,
        余干县: ygx,
        弋阳县: yiyang,
        玉山县: ysx,
      },
      citydata: [
        {
          name: "信州区",
          value: 974,
        },
        {
          name: "广信区",
          value: 500,
        },
        {
          name: "广丰区",
          value: 600,
        },
        {
          name: "玉山县",
          value: 200,
        },
        {
          name: "横峰县",
          value: 150,
        },
        {
          name: "弋阳县",
          value: 354,
        },
        {
          name: "万年县",
          value: 421,
        },
        {
          name: "余干县",
          value: 235,
        },
        {
          name: "德兴市",
          value: 120,
        },
        {
          name: "婺源县",
          value: 111,
        },

        {
          name: "鄱阳县",
          value: 126,
        },
        {
          name: "铅山县",
          value: 147,
        },
      ],
      bartop: [],
      yMax: 1000,
      dataShadow: [],
      mapZoom: 1.1,
      // pointList: [
      //   { name: "测试点位1", value: [118.013945, 28.496383, 1] },
      //   { name: "测试点位2", value: [117.968802, 28.453938, 2] },
      // ],
    };
  },
  methods: {
    init() {
      //   echarts.registerMap("上饶市", shangrao);
      //   this.myChart = echarts.init(this.$refs.char);
      //   this.myChart.setOption(this.option);
      //   this.myChart.on("click", (params) => {
      //     if (params.data.name in this.cityProper) {
      //       console.log("可以下钻");
      //       this.initChars(params.data.name);
      //     }
      //   });
      this.initChars("上饶市");
      // this.initChars("中国");
    },
    initChars(cityName) {
      this.$echarts.dispose(this.$refs.char);
      let cityJson = this.cityProper[cityName];
      console.log(cityJson);
      this.$echarts.registerMap(cityName, cityJson);
      this.myChart = this.$echarts.init(this.$refs.char);
      window.addEventListener("resize", function () {
        this.myChart.resize();
      });
      let option = {
        title: [
          {
            show: false,
            text: "地域分布",
          },
          {
            show: false,
            text: "各区域车辆数",
            top: "110",
            right: "100",
            textStyle: {
              color: "#cccccc",
              fontSize: 12,
            },
          },
        ],
        tooltip: {
          trigger: "item",
        },
        legend: {
          show: false,
        },
        grid: {
          // 仅仅控制条形图的位置
          show: false,
          containLabel: false,
          top: "center",
          right: 50,
          width: "25%",
          height: "60%",
        },
        visualMap: {
          type: "continuous",
          min: 0,
          max: 1000,
          text: ["多", "少"],
          dimension: 0,
          realtime: false,
          left: 0,
          bottom: 30,
          orient: "horizontal",
          itemWidth: 11,
          itemHeight: 80,
          calculable: false,
          inRange: {
            color: ["#1d82cd", "#0e4372"],
            symbolSize: [100, 100],
          },
          outOfRange: {
            color: ["#eeeeee"],
            symbolSize: [100, 100],
          },
          textStyle: {
            color: "#ffffff",
          },
        },
        toolbox: {
          show: false,
        },

        xAxis: [
          {
            type: "value",
            position: "top",
            inside: false,
            axisLabel: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            margin: 10,
          },
        ],
        yAxis: [
          {
            type: "category",
            boundaryGap: true,
            axisLine: {
              show: false,
            },
            inside: true,

            axisLabel: {
              align: "right",
              color: "#000",
              margin: 5,
              showMaxLabel: true,
              textStyle: {
                color: "#cccccc",
              },
            },

            axisTick: {
              show: false,
            },
            data: this.titledata,
          },
        ],
        series: [
          {
            name: "车辆数",
            type: "map",
            mapType: cityName,
            // type: "map3D",
            // map: cityName,
            // boxDepth: '100', //地图倾斜度
            // regionHeight: 1, //地图厚度
            left: "50",
            width: "50%",
            roam: false, //交互效果'move'只能移动
            mapValueCalculation: "sum",
            zoom: this.mapZoom,
            selectedMode: false, //选择高亮
            showLegendSymbol: false,
            // light: {
            //   main: {
            //     intensity: 1.5
            //   }
            // },
            label: {
              normal: {
                textStyle: {
                  color: "#DBF8FF",
                },
                show: true,
              },
              emphasis: {
                textStyle: {
                  color: "#234EA5",
                },
              },
            },
            itemStyle: {
              normal: {
                borderColor: "#07919e", // 边框颜色
                areaColor: "#1c2f59", //  区域颜色
                textStyle: {
                  // 文字颜色
                  color: "#fff",
                },
                shadowBlur: 5, // 图形阴影的模糊大小
                shadowOffsetX: 5, // 阴影水平方向上的偏移距离。
              },
              emphasis: {
                areaColor: "#1c2f59",
                textStyle: {
                  // 文字颜色
                  color: "#fff",
                },
              },
            },
            data: this.citydata
          },
          {
            name: "背景",
            type: "bar",
            roam: false,
            visualMap: false,
            itemStyle: {
              color: "#eeeeee",
              opacity: 0.5,
            },
            label: {
              show: false,
            },
            emphasis: {
              itemStyle: {
                color: "#eeeeee",
                opacity: 0.5,
              },
              label: {
                show: false,
              },
            },
            silent: true,
            barWidth: 18,
            barGap: "-100%",
            data: this.dataShadow,
            animation: false,
          },
          {
            name: "车辆数",
            type: "bar",
            roam: false,
            visualMap: false,
            // itemStyle: {
            //   color: "#60ACFC"
            // },
            barWidth: 18,
            label: {
              normal: {
                show: true,
                color: "#ffffff",
                position: "insideLeft",
              },
              emphasis: {
                show: true,
              },
            },
            data: this.bartop,
          },
        ],
      };
      this.myChart.setOption(option);
      this.myChart.on("click", (params) => {
        if (params.data.name in this.cityProper) {
          console.log("可以下钻");
          this.mapZoom = 0.9;
          this.initChars(params.data.name);
        }
      });
      this.myChart.getZr().on("click", (params) => {
        if (params.target) {
          console.log("非空白");
        } else {
          this.mapZoom = 1.1;
          this.initChars("上饶市");
        }
      });
    },
    init2() {
      this.citydata.sort((a, b) => {
        return b.value - a.value;
      });
      for (var i = 0; i < 12; i++) {
        var top = {
          name: this.citydata[i].name,
          value: this.citydata[i].value,
        };
        this.bartop.push(top);
        this.dataShadow.push(this.yMax);
      }
      this.bartop.sort((a, b) => {
        return a.value - b.value;
      });
      for (let i = 0; i < this.bartop.length; i++) {
        this.titledata.push(this.bartop[i].name);
      }
    },
  },
  mounted() {
    this.init();
  },
  created() {
    this.init2();
  },
};
</script>
 
<style lang="scss" scoped>
</style>