<template>
  <div id="sevendata" :style="{ width: '100%', height: '100%' }"></div>
</template>
<script>
export default {
  props: {
    id: String,
  },
  components: {},
  mounted() {
    this.draw();
  },
  data() {
    return {
      random: Math.round(Math.random() * 80 + 20),
    };
  },
  methods: {
    draw() {
      var app = {};
      var chartDom = document.getElementById("sevendata");
      var myChart = this.$echarts.init(chartDom, "dark");
      var option;
      setTimeout(() => {
        window.onresize = function () {
          // 自适应大小
          myChart.resize();
        };
      }, 100);

      const categories = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
      const data = (function () {
        let res = [];
        let len = 7;
        while (len--) {
          res.push(Math.round(Math.random() * 1000));
        }
        return res;
      })();
      option = {
        backgroundColor: "",
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#283b56",
            },
          },
        },
        toolbox: {
          show: false,
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {},
          },
        },
        grid: {
          top: "20%",
          left: "10%",
          right: "6%",
          bottom: "18%",
        },
        dataZoom: {
          show: false,
          start: 0,
          end: 100,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: categories,
            axisLabel: {
              textStyle: {
                color: "#07DBFF",
              },
            },
            axisTick: {
              alignWithLabel: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              }
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            scale: true,
            name: "",
            axisLabel: {
              textStyle: {
                color: "#07DBFF",
              },
            },
          },
        ],
        series:
        {
          name: "车辆数",
          nameTextStyle: {
            color: '#07DBFF',
            fontSize: '12px',
          },
          type: "line",
          smooth: true,
          symbol: "circle",
          symbolSize: 5,
          sampling: "average",
          itemStyle: {
            color: "#14E5D3",
          },
          stack: "a",
          areaStyle: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#00FFFF",
              },
              {
                offset: 1,
                color: "rgba(0, 193, 241, 0.2)",
              },
            ]),
          },
          data: data,
        }
      };
      app.count = 10;
      option && myChart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>