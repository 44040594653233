<template>
  <div class="container">
    <div class="leftContainer">
      <div class="left-top">
        <div class="title">
          <span>今日车辆趋势</span>
        </div>
        <div style="width: 100%;height:90%;">
          <TodayPerson></TodayPerson>
        </div>
      </div>
      <div class="left-bottom">
        <div class="title">
          <span>历史车辆统计</span>
        </div>
        <div style="width: 100%;height:90%;">
          <SevenData></SevenData>
        </div>
      </div>
    </div>
    <div class="centerContainer">
      <div class="center-top">
        <div class="topLeft">
          <div>今日车辆总数（辆）</div>
          <div>
            <div class="box-item">
              <li :class="{ 'number-item': !isNaN(item) }" v-for="(item, index) in orderNum" :key="index">
                <span v-if="!isNaN(item)">
                  <i ref="numberItem">0123456789</i>
                </span>
              </li>
            </div>
          </div>
          <div style="font-size: 14px;font-weight: 500;color: #FFFFFF;">截止当前时间段</div>
        </div>
        <div class="topRight">
          <div>累计车辆总数（辆）</div>
          <div>
            <div class="box-item">
              <li :class="{ 'number-item': !isNaN(item) }" v-for="(item, index) in orderNum2" :key="index">
                <span v-if="!isNaN(item)">
                  <i ref="numberItem2">0123456789</i>
                </span>
              </li>
            </div>
          </div>
          <div style="font-size: 14px;font-weight: 500;color: #FFFFFF;">2022年5月12上线至今</div>
        </div>
      </div>
      <div class="center-mid">
        <div class="title">
          <span>车辆归属地统计</span>
        </div>
        <div style="width: 100%;height:90%;">
          <MapData></MapData>
        </div>
      </div>
    </div>
    <div class="rightContainer">
      <div class="right-top">
        <div class="title">
          <span>停车场情况展示</span>
        </div>
        <div class="right-top-top">
          <div class="top-top-left">
            <span style="font-size: 24px;font-weight: bold;">40%</span>
            <span style="width: 32px;margin-left: 10px;font-size: 16px;">占用情况</span>
          </div>
          <div class="top-top-right">
            <span style="width: 32px;margin-right: 10px;font-size: 16px;">满载景区</span>
            <span style="font-size: 24px;font-weight: bold;">16个</span>
          </div>
        </div>
        <div class="tableTitle">
          <div class="titleItem" style="width: 33%;">景区名</div>
          <div class="titleItem" style="width: 33%;">空闲车位</div>
          <div class="titleItem" style="width: 33%;">总车位</div>
        </div>
        <div class="cellCon">
          <div class="cell" v-for="(item, index) in dataList" :key="index">
            <div class="cellItem" style="width: 33%;">{{ item.name }}</div>
            <div class="cellItem" style="width: 33%;color: #17CAF0;">{{ item.nowNum }}</div>
            <div class="cellItem" style="width: 33%;">{{ item.maxNum }}</div>
          </div>
        </div>
      </div>
      <div class="right-bottom">
        <div class="title">
          <span>景区车辆情况统计</span>
        </div>
        <div style="width: 100%;height: 89%;position: relative;">
          <CircleData :id="'device'"></CircleData>
          <div class="circleChild">
            <div class="childTitle">占比统计</div>
            <span>500</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CircleData from "./components/circleData.vue"
import TodayPerson from "./components/todayPerson.vue";
import SevenData from "./components/sevenData.vue";
import MapData from "./components/mapData.vue";
export default {
  components: {
    CircleData,
    TodayPerson,
    SevenData,
    MapData
  },
  mounted() {
    this.increaseNumber()
  },
  data() {
    return {
      orderNum: ['0', '0', '0', '0', '0', '0'],
      orderNum2: ['0', '0', '0', '0', '0', '0'],
      newNumber: 2150,
      newNumber2: 36565,
      dataList: [
        {
          name: "景区A",
          nowNum: 1569,
          maxNum: 2000,
        },
        {
          name: "景区B",
          nowNum: 1569,
          maxNum: 2000,
        },
        {
          name: "景区C",
          nowNum: 1569,
          maxNum: 2000,
        },
        {
          name: "景区D",
          nowNum: 1569,
          maxNum: 2000,
        },
        {
          name: "景区E",
          nowNum: 1569,
          maxNum: 2000,
        },
        {
          name: "景区F",
          nowNum: 1569,
          maxNum: 2000,
        }
      ]
    };
  },
  methods: {
    // 定时增长数字
    increaseNumber() {
      let self = this
      this.timer = setInterval(() => {
        let random = this.getRandomNumber(1, 5)
        self.newNumber = self.newNumber + random
        self.newNumber2 = self.newNumber2 + random
        this.toOrderNum(self.newNumber) // 这里输入数字即可调用
        this.toOrderNum2(self.newNumber2) // 这里输入数字即可调用
        self.setNumberTransform()
        self.setNumberTransform2()
      }, 5000)
    },
    getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    // 设置文字滚动
    setNumberTransform() {
      const numberItems = this.$refs.numberItem // 拿到数字的ref，计算元素数量
      const numberArr = this.orderNum.filter(item => !isNaN(item))
      // 结合CSS 对数字字符进行滚动,显示订单数量
      for (let index = 0; index < numberItems.length; index++) {
        const elem = numberItems[index]
        elem.style.transform = `translate(-50%, -${numberArr[index] * 10}%)`
      }
    },
    setNumberTransform2() {
      const numberItems = this.$refs.numberItem2 // 拿到数字的ref，计算元素数量
      const numberArr = this.orderNum2.filter(item => !isNaN(item))
      // 结合CSS 对数字字符进行滚动,显示订单数量
      for (let index = 0; index < numberItems.length; index++) {
        const elem = numberItems[index]
        elem.style.transform = `translate(-50%, -${numberArr[index] * 10}%)`
      }
    },
    // 处理总订单数字
    toOrderNum(num) {
      num = num.toString()
      // 把订单数变成字符串
      if (num.length < 6) {
        num = '0' + num // 如未满八位数，添加"0"补位
        this.toOrderNum(num) // 递归添加"0"补位
      } else if (num.length === 6) {
        this.orderNum = num.split('') // 将其便变成数据，渲染至滚动数组
      } else {
        // 订单总量数字超过八位显示异常
        console.log('订单总量数字过大，显示异常，请联系客服');
      }
    },

    toOrderNum2(num) {
      num = num.toString()
      // 把订单数变成字符串
      if (num.length < 6) {
        num = '0' + num // 如未满八位数，添加"0"补位
        this.toOrderNum2(num) // 递归添加"0"补位
      } else if (num.length === 6) {
        this.orderNum2 = num.split('') // 将其便变成数据，渲染至滚动数组
      } else {
        // 订单总量数字超过八位显示异常
        console.log('订单总量数字过大，显示异常，请联系客服');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  font-size: 14px;
  background-image: url("../../assets/image/bg-wangge.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: right bottom;
  padding: 1% 1.6% 1.6% 1.6%;

  .baseCell {
    width: 100%;
    height: 36px;
    background-image: url("../../assets/image/common05.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    display: flex;
    align-items: center;
  }

  .title {
    width: 100%;
    height: 50px;
    padding: 0px 50px;
    font-size: 20px;
    font-weight: 400;
    color: #17CAF0;
    display: flex;
    align-items: center;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 18px;
      left: 20px;
      width: 14px;
      height: 14px;
      background: #17CAF0;
      opacity: 0.3;
      border-radius: 50%;
    }

    &::after {
      content: '';
      position: absolute;
      top: 21px;
      left: 23px;
      width: 8px;
      height: 8px;
      background: #17CAF0;
      border-radius: 50%;
    }
  }

  .conTop {
    width: 100%;
    height: 54%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0px 0px;
  }

  .conBottom {
    width: 100%;
    height: 45%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0px 0px;
  }

  .leftContainer {
    width: 25%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    flex-direction: column;

    .left-top {
      @extend .conTop;
      background-image: url("../../assets/image/common01.png");
    }

    .left-bottom {
      @extend .conBottom;
      background-image: url("../../assets/image/common02.png");
    }
  }

  .centerContainer {
    width: 47%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .center-top {
      @extend .conTop;
      height: 23%;
      background-image: url("../../assets/image/car03.png");
      display: flex;
      justify-content: space-between;

      .topLeft {
        width: 42%;
        height: 100%;
        padding-top: 7%;
        padding-bottom: 2%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        font-size: 18px;
        font-weight: 400;
        color: #00D8FF;

        /*订单总量滚动数字设置*/
        .box-item {
          position: relative;
          height: 52px;
          font-size: 36px;
          font-weight: bold;
          color: #00D8FF;
          line-height: 36px;
          text-align: center;
          list-style: none;
          writing-mode: vertical-lr;
          text-orientation: upright;
          /*文字禁止编辑*/
          -moz-user-select: none;
          /*火狐*/
          -webkit-user-select: none;
          /*webkit浏览器*/
          -ms-user-select: none;
          /*IE10*/
          -khtml-user-select: none;
          /*早期浏览器*/
          user-select: none;
          /* overflow: hidden; */
        }

        /*滚动数字设置*/
        .number-item {
          width: 47px;
          height: 52px;
          background: #ccc;
          list-style: none;
          margin-right: 5px;
          background: url("../../assets/image/person06.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: 0px 0px;

          &>span {
            position: relative;
            display: inline-block;
            margin-right: 10px;
            width: 100%;
            height: 100%;
            writing-mode: vertical-rl;
            text-orientation: upright;
            overflow: hidden;

            &>i {
              font-style: normal;
              position: absolute;
              top: 8px;
              left: 50%;
              transform: translate(-50%, 0);
              transition: transform 1s ease-in-out;
              letter-spacing: 10px;
            }
          }
        }

        .number-item:last-child {
          margin-right: 0;
        }
      }

      .topRight {
        width: 42%;
        height: 100%;
        padding-top: 7%;
        padding-bottom: 2%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 18px;
        font-weight: 400;
        color: #00D8FF;

        /*订单总量滚动数字设置*/
        .box-item {
          position: relative;
          height: 52px;
          font-size: 36px;
          font-weight: bold;
          color: #00D8FF;
          line-height: 36px;
          text-align: center;
          list-style: none;
          writing-mode: vertical-lr;
          text-orientation: upright;
          /*文字禁止编辑*/
          -moz-user-select: none;
          /*火狐*/
          -webkit-user-select: none;
          /*webkit浏览器*/
          -ms-user-select: none;
          /*IE10*/
          -khtml-user-select: none;
          /*早期浏览器*/
          user-select: none;
          /* overflow: hidden; */
        }

        /*滚动数字设置*/
        .number-item {
          width: 47px;
          height: 52px;
          background: #ccc;
          list-style: none;
          margin-right: 5px;
          background: url("../../assets/image/person06.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: 0px 0px;

          &>span {
            position: relative;
            display: inline-block;
            margin-right: 10px;
            width: 100%;
            height: 100%;
            writing-mode: vertical-rl;
            text-orientation: upright;
            overflow: hidden;

            &>i {
              font-style: normal;
              position: absolute;
              top: 8px;
              left: 50%;
              transform: translate(-50%, 0);
              transition: transform 1s ease-in-out;
              letter-spacing: 10px;
            }
          }
        }

        .number-item:last-child {
          margin-right: 0;
        }
      }
    }

    .center-mid {
      @extend .conTop;
      height: 75%;
      background-image: url("../../assets/image/car01.png");
    }
  }

  .rightContainer {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .right-top {
      @extend .conTop;
      background-image: url("../../assets/image/common01.png");
      // padding: 15px;

      .right-top-top {
        width: calc(100% - 30px);
        height: 26%;
        margin: 0px 15px;
        background-image: url("../../assets/image/car02.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: 0px 0px;
        display: flex;
        justify-content: space-between;
        color: #00D8FF;

        .top-top-left {
          width: 32%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        .top-top-right {
          width: 32%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }

      .tableTitle {
        width: 100%;
        height: 11%;
        display: flex;
        align-items: center;
        padding: 0px 3%;
        margin-top: 2%;
        color: #17CAF0;


        .titleItem {
          display: flex;
          justify-content: center
        }
      }

      .cellCon {
        width: 100%;
        height: 48%;
        padding: 0px 3%;
        // display: flex;
        // flex-direction: column;
        overflow: auto;

        .cell {
          @extend .baseCell;
          font-size: 14px;
          font-weight: bold;
          color: #FFFFFF;
          margin-bottom: 16px;

          .cellItem {
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    .right-bottom {
      @extend .conBottom;
      background-image: url("../../assets/image/common02.png");

      .circleChild {
        width: 50%;
        height: 70%;
        position: absolute;
        top: 0px;
        left: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 56px;
        font-weight: 400;
        color: #17CAF0;

        .childTitle {
          font-size: 19px;
          font-weight: bold;
          color: #FFFFFF;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: 10px;
            left: -18px;
            width: 6px;
            height: 6px;
            background: #FFE995;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
</style>